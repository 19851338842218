export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "releaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release Note"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resource Package Content"])}
      },
      "zh": {
        "releaseNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版本说明"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包内容"])}
      }
    }
  })
}
