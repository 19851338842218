export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation System"])},
        "install_by_default_true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["install by default"])},
        "install_by_default_false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["install on selection"])},
        "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addons"])},
        "network_plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cni plugins"])},
        "dependency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dependencies"])},
        "package_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Content"])}
      },
      "zh": {
        "os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作系统"])},
        "install_by_default_true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["默认安装"])},
        "install_by_default_false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选安装"])},
        "addon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可选组件"])},
        "network_plugin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络插件"])},
        "dependency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["依赖组件"])},
        "package_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资源包内容"])}
      }
    }
  })
}
